import React from 'react'
import SEO from '../components/seo'
import Logo from '../components/Header/Logo'
import styled from 'styled-components'
import colors from '../lib/colors'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  background-color: ${colors.bluedark};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: ${colors.bluedark};
`

const Main = styled.main`
  text-align: center;
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 25px;
`

const StyledLogo = styled(Logo)`
  width: 200px;
`

const StyledParagraph = styled.p`
  margin-bottom: 15px;
`

const StyledHeader = styled.h1`
  font-size: 24px;
  margin-bottom: 25px;
`

const BackHome = styled(Link)`
  text-decoration: underline;
  color: inherit;
`

const NotFoundPage = () => (
  <Wrapper>
    <Content>
      <Main>
        <SEO title="404: Not found" />
        <LogoContainer>
          <StyledLogo />
        </LogoContainer>
        <StyledHeader>Page Not Found</StyledHeader>
        <StyledParagraph>This link does not exist!</StyledParagraph>
        <StyledParagraph>
          Click <BackHome to="/">here</BackHome> to go back to the homepage.
        </StyledParagraph>
      </Main>
      <footer>© {new Date().getFullYear()}, Torq Interface, Inc.</footer>
    </Content>
  </Wrapper>
)

export default NotFoundPage
